import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'

import Logo from '../images/assets/logo.svg'

export default function Navigation() {
  const data = useStaticQuery(graphql`
    query {
      navigation: sanityNavigation(name: { eq: "main-navigation" }) {
        title
        sections {
          title
          target {
            slug {
              current
            }
          }
        }
      }
    }
  `)
  const { navigation } = data

  return (
    <nav className="flex justify-between bg-transparent text-white w-screen">
      <div className="px-5 xl:px-12 py-6 flex flex-col lg:flex-row w-full items-center">
        <Link className="text-3xl font-heading" to="/">
          <Logo alt="hiustoimi logo" />
        </Link>
        {/* <!-- Nav Links --> */}
        <ul className="flex flex-col lg:flex-row px-4 mx-auto lg:space-x-12">
          {navigation.sections.map((item, index) => (
            <li key={index}>
              <Link
                className="sm:p md:p lg:p xl:p-4 text-black hover:text-aquamarine-dark font-unica-one tracking-widest"
                activeClassName="text-aquamarine-dark"
                to={`${item.target.slug.current.length === 1 ? '' : '/'}${
                  item.target.slug.current
                }`}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {/* <!-- Responsive navbar --> */}
      {/* <a className="xl:hidden flex mr-6 items-center" href="#">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 hover:text-gray-200"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
          />
        </svg>
        <span className="flex absolute -mt-5 ml-4">
          <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-pink-400 opacity-75" />
          <span className="relative inline-flex rounded-full h-3 w-3 bg-pink-500" />
        </span>
      </a>
      <a className="navbar-burger self-center mr-12 xl:hidden" href="#">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 hover:text-gray-200"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </a> */}
    </nav>
  )
}
