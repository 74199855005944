exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aitohiusperuukki-js": () => import("./../../../src/pages/aitohiusperuukki.js" /* webpackChunkName: "component---src-pages-aitohiusperuukki-js" */),
  "component---src-pages-ajanvaraus-ja-hinnasto-js": () => import("./../../../src/pages/ajanvaraus-ja-hinnasto.js" /* webpackChunkName: "component---src-pages-ajanvaraus-ja-hinnasto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kiitos-js": () => import("./../../../src/pages/kiitos.js" /* webpackChunkName: "component---src-pages-kiitos-js" */),
  "component---src-pages-ota-yhteytta-js": () => import("./../../../src/pages/ota-yhteytta.js" /* webpackChunkName: "component---src-pages-ota-yhteytta-js" */),
  "component---src-pages-parturi-kampaamo-js": () => import("./../../../src/pages/parturi-kampaamo.js" /* webpackChunkName: "component---src-pages-parturi-kampaamo-js" */)
}

