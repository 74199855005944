// custom typefaces
import '@fontsource/voltaire'
import '@fontsource/unica-one'
// global css
import './src/styles/global.css'

import { AnimatePresence } from 'framer-motion'
import React from 'react'

import Layout from './src/components/Layout'

export function wrapPageElement({ element, props }) {
  return (
    <Layout location={props.location} {...props}>
      <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
    </Layout>
  )
}
