import React from 'react'

import FaIcon from '../images/assets/fa-logo.svg'
import IgIcon from '../images/assets/ig-logo.svg'

const iconSize = '55px'

const FooterLink = ({ children, link }) => {
  return (
    <a href={link}>
      <span className="font-voltaire text-base text-black hover:text-gray-400 tracking-wider">
        {children}
      </span>
    </a>
  )
}
const FooterText = ({ children }) => {
  return <span className="font-voltaire text-base text-black tracking-wider">{children}</span>
}

export default function Footer() {
  return (
    <footer className="w-max mx-auto">
      <div className="bg-aquamarine flex flex-col justify-center items-center m-10 p-10">
        <FooterText>Hiustoimen</FooterText>
        <FooterText>parturi- ja</FooterText>
        <FooterText>kampaamopalvelut</FooterText>
        <FooterText>Vanha Hämeentie 110</FooterText>
        <FooterText>20540 Turku</FooterText>
        <FooterLink link="mailto:info@hiustoimi.fi">info@hiustoimi.fi</FooterLink>
        <FooterText>Ajanvaraukset:</FooterText>
        <FooterLink link="tel:+358449410380">044 941 0380 / Ire</FooterLink>
        <FooterLink link="tel:+358407676384">040 767 6384 / Maarika</FooterLink>
        <div className="flex mt-5">
          <a href="https://www.facebook.com/hiuksetire">
            <FaIcon height={iconSize} width={iconSize} />
          </a>
          <a href="https://www.instagram.com/hiuksetire/?hl=fi">
            <IgIcon height={iconSize} width={iconSize} />
          </a>
        </div>
      </div>
    </footer>
  )
}
