import * as React from 'react'

import Icon from '../images/assets/background_letter.svg'
import Footer from './Footer'
import Navigation from './Navigation'

const Layout = ({ children }) => (
  <div className="relative  min-h-screen">
    <Icon className="fixed inset-0 m-auto" style={{ zIndex: '-1' }} />
    <header>
      <Navigation />
    </header>
    {children}
    <Footer />
  </div>
)
export default Layout
